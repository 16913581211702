import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LocalCacheService, LocalStorageService, TimeService } from '@ifa/ngx-data2-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http'; 
import { Data2Service } from './services/data2/data2.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Globals } from './globals';

import { SafePipe, SafePipeType} from './pipes/safe.pipe';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller, APP_BASE_HREF } from '@angular/common';
//import { filter, pairwise } from 'rxjs';
import { AuthModule } from './auth.module';
// import { filter, pairwise } from 'rxjs/dist/types';


@Injectable()
@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
          //enabled: environment.production,
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
      })], 
      providers: [
        Data2Service,
        LocalCacheService,
        LocalStorageService,
        TimeService,
        // {provide: APP_BASE_HREF, useValue: '/'}, //No need for <base href="/"> which messes with SVG urls
        Globals,
        SafePipe, 
        provideClientHydration(),
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
      ] })
export class AppModule {
  // constructor( private router: Router, private viewportScroller: ViewportScroller ) {
  //   this.router.events.pipe(
  //     filter(  e=> e instanceof Scroll ),
  //     pairwise()
  //   ).subscribe( ( eventPair:any ) => {
  //     const previousEvent = eventPair[ 0 ];
  //     const event = eventPair[ 1 ];
  //     if ( event.position ) {
  //       // backward navigation
  //       this.viewportScroller.scrollToPosition( event.position );
  //     } else if ( event.anchor ) {
  //       // anchor navigation
  //       this.viewportScroller.scrollToAnchor( event.anchor );
  //     } else {
  //       // forward navigation
  //       // if ( (previousEvent.routerEvent.urlAfterRedirects.split( '?' )[ 0 ]) !== event.routerEvent.urlAfterRedirects.split( '?' )[ 0 ] ) {
  //       //   // Routes don't match, this is actual forward navigation
  //       //   // Default behavior: scroll to top
  //       //   this.viewportScroller.scrollToPosition( [0, 0] );
  //       // }
  //       const previousUrl = previousEvent?.routerEvent?.urlAfterRedirects || '';  
  //       const currentUrl = event?.routerEvent?.urlAfterRedirects || '';  
  //       if ( (previousUrl.split( '?' )[ 0 ]) !== currentUrl.split( '?' )[ 0 ] ) {  
  //         // Routes don't match, this is actual forward navigation  
  //         // Default behavior: scroll to top 
  //         //console.log("appModule routerEvent urlAfterRedirects > previousUrl:",previousUrl,"currentUrl:",currentUrl) 

  //         if(currentUrl.indexOf("/calculator")!=0 && previousUrl.indexOf("/calculator")!=0){ //Do not scroll the ifa index calculator on 'calculate'
  //           this.viewportScroller.scrollToPosition( [0, 0] ); 
  //         }
  //       }  
  //     }
  //   } );
  // }
}