import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class JsonldserviceService {

  static scriptType = 'application/json+ld';

constructor(
  @Inject(DOCUMENT) private _document: Document
) { }


  static pageSchema = (name : string, url :string, description: string) => 
    { 
      return {
        '@context'  :
        'https://schema.org',
      '@type'     :
        'WebPage',
      url         : url,
      name        : name,
      description : description,
      'publisher' :
      {
        '@type'     :'Organization',
        'logo'      : {
          '@type'   : 'ImageObject',
          'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
        },
        url         :'https://www.ifa.com',
        name        :'Index Fund Advisors',
        foundingDate:"1999-03-01T00:00:00",
        image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
        founder:"Mark T. Hebner",
        description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
      }
      }
    };

  static articleSchema = (name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number) => {
    return {
      '@context'  :
        'https://schema.org',
      '@type'     :
        'Article',
      url         : url,
      name        : name,
      headline    : name, 
      identifier  : id,
      datePublished: publishDate,
      dateModified: modifiedDate,
      author      : author,
      keywords    : keywords,
      description : description,
      articleBody : text,
      thumbnailUrl: imageUrl,
      image       : imageUrl,
      'publisher' :
      {
        '@type'     :'Organization',
        'logo'      : {
          '@type'   : 'ImageObject',
          'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
        },
        url         :'https://www.ifa.com',
        name        :'Index Fund Advisors',
        foundingDate:"1999-03-01T00:00:00",
        address     : mailingAddress,
        telephone   :phoneLocal,
        faxNumber   :faxNumber,
        image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
        founder:"Mark T. Hebner",
        description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
      },
      'interactionStatistic':[{
          '@type':    'InteractionCounter',
          'interactionType': 'https:///schema.org/watchAction',
          'userInteractionCount': views
      }]
    };
  };

  static advisorSchema = (name : string, url :string, description: string) => 
    { 
      return {
        '@context'  :'https://schema.org',
      '@type'       :'Person',
      url           : url,
      name          : name,
      description   : description,
      'publisher'   :
      {
        '@type'     :'Organization',
        'logo'      : {
          '@type'   : 'ImageObject',
          'url'     : 'https://services.ifa.com/art/images/1614_2019-5-7-11-52-54.jpg'
        },
        url         :'https://www.ifa.com',
        name        :'Index Fund Advisors',
        foundingDate:"1999-03-01T00:00:00",
        image:"https://services.ifa.com/art/images/399_2013-10-1-16-47-5.jpg",
        founder:"Mark T. Hebner",
        description:"IFA is a Registered Investment Adviser with the U.S. Securities and Exchange Commission that provides investment advice to individuals, trusts, corporations, non-profits, and public and private institutions.",
      }
      }
    };

  getPageSchema(name : string, url :string, description: string): Record<string, any> {
    return JsonldserviceService.pageSchema(name, url, description);
  }

  getArticleSchema(name : string, url :string, id : string, publishDate: string, modifiedDate: string, author: string, keywords: string, description: string, text: string, imageUrl: string, phoneLocal: string, faxNumber: string, mailingAddress: string, views:number): Record<string, any> {
    return JsonldserviceService.articleSchema(name , url, id , publishDate, modifiedDate, author, keywords, description, text, imageUrl, phoneLocal, faxNumber, mailingAddress, views);
  }

  getAdvisorSchema(name : string, url :string, description: string): Record<string, any> {
    return JsonldserviceService.advisorSchema(name, url, description);
  }

  removeStructuredData(): void {
		const els: Element[] = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
		let script;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.setAttribute('type', JsonldserviceService.scriptType);


    if(!shouldAppend)
    {
      script.appendChild(document.createTextNode(","));
    }
		script.appendChild(document.createTextNode(JSON.stringify(schema)));
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}

}
