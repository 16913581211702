import { FavoriteChart } from "./favoriteChart";

export class ChartFolder {
    public id: number = 0;
    public order: number = 0; 
    public name: string = "100%";
    public charts: Array<FavoriteChart> = [];
}

export interface ChartTotalViews 
{
    totalviews: number;
}

  