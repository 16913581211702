import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {


 public hasBeenPopulatedFromLS =false;

  public readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions = {
    id:`readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions`,
    question:`<ol>
    <li>I have read and understand IFA's Back-testing Disclosures and Index Descriptions.</li>
    <li>I have access to sufficient resources and possess the financial expertise to independently analyze hypothetical back-tested performance data.</li>
    <li>I understand the RISKS, LIMITATIONS and ASSUMPTIONS made in calculating the hypothetical back-tested performance data.</li>
    <li>I would like information on investing and in particular hypothetical back-tested data of indexes, index portfolios, mutual funds and exchange traded funds.</li>
    <li>The use of hypothetical back-tested performance data is relevant to my investment analysis, objectives, and financial situation. By confirming below, I am requesting access to this data.</li>
    </ol>`,
    textTrue:`Yes`,
    textFalse:`No`, 
    answer: '',
    answerDate:null      
  };

   //We will use an array to look up the consent by id later.
   privateConsentArray:Array<any> = [    
      this.readAndUnderstoodIFAsBacktestingDisclosuresAndIndexDescriptions
    ];


  constructor(private router:Router) { }

  isFullyConsented(caller:string=""){
    console.log(`Consent Service > isFullyConsented ${caller}` ,this.privateConsentArray)
    return this.privateConsentArray.every(x=>x.answer=='true');
  }

  populateMemoryConsents() {   
    if(!this.hasBeenPopulatedFromLS){

      console.log("load consents from storage.")
      var _ls_consents = localStorage.getItem("consents");               
      if(_ls_consents){
        var consents = JSON.parse(_ls_consents);
        console.log("localStorage consents:",consents)

        for(var i=0;i<this.privateConsentArray.length;i++){
          for(var j=0;j<consents.length;j++){
            if(this.privateConsentArray[i].id == consents[j].id 
              && this.privateConsentArray[i].question == consents[j].question ){
                this.privateConsentArray[i].answer = consents[j].answer;
                this.privateConsentArray[i].answerDate = consents[j].answerDate
              }
          }
        }
      }
      this.hasBeenPopulatedFromLS=true;  
    }  
  }

  interceptRoute(url:string,target="_blank"){
    this.populateMemoryConsents();
    var fc = this.isFullyConsented();   
    if(fc){    
      if(url.indexOf("https")>-1){
        if (url.startsWith('/')) {  
          url = url.slice(1);  
        }      
        window.open(url,target);
      }
      else{
        this.router.navigateByUrl(url)
      }
    }
    else{
      localStorage.setItem("intercepted-route", JSON.stringify({
        url: url,
        date: new Date()
      }));
      this.router.navigateByUrl("/required-consent")
    }
  }
}
