import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { MasterGuard } from './guards/master.guard';
import { ArticleListComponent } from './pages/articles/article-list.component';
import { PagesComponent } from './pages/pages.component';
import { SurveyComponent } from './pages/survey/survey.component';

const routes: Routes = [
{ 
  path:"",
   loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule)    
},
{ 
  path:"required-consent-stand-alone",
  loadChildren:() => import('./pages/required-consent-stand-alone/required-consent-stand-alone.module').then(m => m.RequiredConsentStandAloneModule)
},
{ 
  path:"survey",
  loadChildren:() => import('./pages/survey/survey.module').then(m => m.SurveyModule), canActivate:[MasterGuard]
},
{ 
  path:   'signin-oidc',  
  loadChildren: () => import('./pages/signin-oidc/signin-oidc.module').then(m => m.SigninOidcModule)
},
{ 
  path:   'signout-oidc',  
  loadChildren: () => import('./pages/signout-oidc/signout-oidc.module').then(m => m.SignoutOidcModule)
},
{
  path:'**',
  redirectTo:'404'
}


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        enableViewTransitions:true,
        //initialNavigation:'enabledBlocking', //
        //scrollOffset: [0, 10],
        //relativeLinkResolution: 'legacy'
    })
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
