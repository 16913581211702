import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeadService {

  constructor(
    private titleService: Title, 
    private meta: Meta) { }

  setTitle(newTitle:string=""){
    this.titleService.setTitle(newTitle + ' | Index Fund Advisors, Inc.');
    this.meta.updateTag({ property: 'og:title', content: newTitle });
    this.meta.updateTag({ name: 'twitter:title', content: newTitle });
  }
  setDescription(newDescription:string=""){
    this.meta.updateTag({ name: 'description', content: newDescription });
    this.meta.updateTag({ property: 'og:description', content: newDescription });
    this.meta.updateTag({ name: 'twitter:description', content: newDescription });
  }
  setKeywords(newKeywords:string=""){
    this.meta.updateTag({ name: 'keywords', content: newKeywords });
  }

  setImageOG(imageUrl:string=""){
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });    
  }

  setTeaser(teaser:string=""){
    this.meta.updateTag({ name: 'twitter:image:alt', content: teaser });
  }

  setType(pageType:string=""){
    this.meta.updateTag({ property: 'og:type', content: pageType});
  }
  setMetaProperty(property:string="", content:string=""){
    this.meta.updateTag({ property: property, content: content});
  }

  removeKeywords(newKeywords:string=""){
    this.meta.removeTag( 'keywords');
  }
  removeDescription(){
    this.meta.removeTag('description');
    this.meta.removeTag('og:description');
    this.meta.removeTag('twitter:description');
  }
  removeType(){
    this.meta.removeTag('og:type');
  }
  removeImageOG(){
    this.meta.removeTag('og:image');
    this.meta.removeTag('twitter:image');
    this.meta.removeTag('twitter:card');
  }
  removeMetaProperty(property:string=""){
    this.removeMetaProperty(property);
  }
}
